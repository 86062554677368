<template>
  <div v-loading="loading">
    <!--    上部开始-->
    <div class="statistical-top">
      <div class="el-nav">
        <div class="goback vertical-center">
          <div class="goback-left vertical-center" @click="goBack">
            <img class="back" src="~assets/images/data/return.png" alt="" /><img class="back_hover" src="~assets/images/data/back_hover.png" alt="" /><span>返回</span>
          </div>
          <p class="line"></p>
          <div class="tag">{{ type }}</div>

          <div class="goback-text">{{ title }}</div>
          <div class="el-nav-right">
            每天
            <span>1</span>
            点左右更新前一日数据
          </div>
        </div>
        <el-divider></el-divider>
        <div class="statistical-center">
          <div class="btn-list vertical-center">
            <div class="left">数据总览</div>
          </div>
          <div class="statistical-center-list space-between">
            <div class="statistical-center-item live">
              <div class="statistical-center-item-title">参会人数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.participants_num) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag class="noBg">-</el-tag>
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均参会量：-</span>
              </div>
            </div>
            <div class="statistical-center-item live">
              <div class="statistical-center-item-title">观看人数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.watch_num) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag class="noBg">-</el-tag>
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均观看量：-</span>
              </div>
            </div>
            <div class="statistical-center-item live">
              <div class="statistical-center-item-title">回放观看人数</div>
              <div class="statistical-center-item-num">
                {{ total.playback_read_man_count }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.playback_read_man_count) === 0 ? 'noBg' : Number(yesterday_data.playback_read_man_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.playback_read_man_count) === 0 ? '' : Number(yesterday_data.playback_read_man_count) > 0 ? '+' : '-'
                  }}{{ yesterday_data.playback_read_man_count }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均观看量：{{ week_data.playback_read_man_count }}</span>
              </div>
            </div>
            <div class="statistical-center-item live">
              <div class="statistical-center-item-title">回放播放次数</div>
              <div class="statistical-center-item-num">
                {{ total.views_num }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.playback_read_count) === 0 ? 'noBg' : Number(yesterday_data.playback_read_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.playback_read_count) > 0 ? '+' : '' }}{{ Number(yesterday_data.playback_read_count) }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均播放量：{{ week_data.playback_read_count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">回放观看人员统计</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtDetail(3)">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="replayTableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="detail"
          >
            <el-table-column prop="date" label="昵称" width="150">
              <template slot-scope="scope">
                <div>{{ scope.row.user.nickname }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="people_count" label="手机号" sortable align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.user.mobile }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="people_count" label="观看时长" sortable align="center">
              <template slot-scope="scope">
                <div>
                  <span v-if="parseInt(Number(scope.row.view_time) / 3600) > 0">{{ parseInt(Number(scope.row.view_time) / 3600) }}时</span>
                  <span v-if="parseInt(parseInt(Number(scope.row.view_time) % 3600) / 60) > 0">{{ parseInt(parseInt(Number(scope.row.view_time) % 3600) / 60) }}分</span>
                  <span v-if="Number(scope.row.view_time % 60) > 0">{{ Number(scope.row.view_time % 60) }}秒</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="collect_count" label="观看次数" sortable align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.views_num }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="comment_count" label="开始观看时间" sortable align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.created_at }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="replayTableData.length > 0 && replayTotalNum > 10"
          class="page"
          background
          @current-change="replayHandleCurrentChange"
          :current-page="replayPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="replayPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="replayTotalNum"
        >
        </el-pagination>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">分日报表</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtDetail(4)">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="detail"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column prop="playback_read_man_count" label="回放观看人数" sortable align="center"> </el-table-column>
            <el-table-column prop="playback_read_count" label="回放播放次数" sortable align="center"> </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="tableData.length > 0 && totalNum > 10"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout=" prev, pager, next,total, jumper"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      activeName: 'first',
      value: 0,
      radio: 0,
      readingsNum: 698457,
      likesNum: 15,
      commentsNum: 0,
      cocollectionsNum: 0,
      readingsOldNum: 0,
      likesOldNum: 15,
      commentsOldNum: -2,
      cocollectionsOldNum: 0,
      noDow: false,
      type: '直播',
      title: '',
      model_id: '',
      page: 1,
      pageSize: 10,
      totalNum: 0,
      replayPage: 1,
      replayPageSize: 10,
      replayTotalNum: 0,
      yesterday_data: {},
      week_data: {},
      total: {},
      tableData: [],
      replayTableData: [],
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.model_id = this.$route.query.id;
    if (this.model_id) {
      this.getOverView(this.model_id);
      this.getDayStatisticsList(this.model_id);
      this.getReplayList(this.model_id);
    }
  },
  mounted() {},
  methods: {
    // 下载
    downtDetail(type) {
      let url = `${this.$api.liveExport}?model_id=${this.model_id}&type=${type}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);

        window.open(res.data.data);
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDayStatisticsList(this.model_id);
    },
    replayHandleCurrentChange(val) {
      this.replayPage = val;
      this.getReplayList(this.model_id);
    },
    // 获取数据总览
    getOverView(id) {
      this.loading = true;
      let url = `${this.$api.overview}?circle_uuid=${this.circle_uuid}&model_id=${id}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.yesterday_data = res.data.data.yesterday_data;
          this.week_data = res.data.data.week_data;
          this.total = res.data.data.total;
        }
      });
    },
    // 获取分日列表
    getDayStatisticsList(id) {
      let url = `${this.$api.dayDateDetail}?&page=${this.page}&pageSize=${this.pageSize}&model_id=${id}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.tableData = res.data.data.data;
          this.totalNum = res.data.data.meta.total;
        }
      });
    },
    // 获取回放观看人员统计
    getReplayList(id) {
      let url = `${this.$api.detailUser}?&page=${this.replayPage}&pageSize=${this.replayPageSize}&model_id=${id}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.replayTableData = res.data.data.data;

          this.replayTotalNum = res.data.data.meta.total;
        }
      });
    },
    goBack() {
      this.$router.push({
        path: '/analysis/live/live',
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
.btn-list {
  .left {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #71777d;
  }
}
.el-nav {
  .statistical-center {
    .statistical-center {
      margin-top: 30px;
    }
  }
}
</style>
